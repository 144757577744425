import { createEntityAdapter, EntityState } from '@ngrx/entity'

import { FlamingoForm } from '@app/modules/flamingo/models/flamingo-form.model'
import {
    CreateOrUpdateFlamingoFormAction, CreateOrUpdateFlamingoFormSuccessAction, DeleteFlamingoFormAction, DeleteFlamingoFormFailAction, DeleteFlamingoFormSuccessAction, FlamingoAction, GetFlamingoFormsAction, GetFlamingoFormsFailAction,
    GetFlamingoFormsSuccessAction
} from './flamingo.actions'
import { FlamingoFormsQuery } from '@app/modules/flamingo/models/flamingo-form-query.model'

export interface FlamingoState extends EntityState<FlamingoForm> {
    totalFlamingoFormsCount: number
    isListLoading: boolean
    hasError: boolean
    isFormCreatingOrUpdating: boolean
    currentQuery: FlamingoFormsQuery
}

export const adapter = createEntityAdapter<FlamingoForm>({
    selectId: flamingoForm => flamingoForm.formId
})

const initialFlamingoState: FlamingoState = adapter.getInitialState({
    totalFlamingoFormsCount: -1,
    isListLoading: true,
    hasError: false,
    isFormCreatingOrUpdating: false,
    currentQuery: {} as FlamingoFormsQuery
})

export const reducer = (state: FlamingoState = initialFlamingoState, action: FlamingoAction): FlamingoState => {
    switch (action.type) {
        case GetFlamingoFormsFailAction.type: {
            return {
                ...state,
                isListLoading: false,
                hasError: true
            }
        }

        case GetFlamingoFormsAction.type: {
            return {
                ...state,
                isListLoading: true,
                currentQuery: {...action}
            }
        }

        case GetFlamingoFormsSuccessAction.type: {
            // Don't know why but the compilier can't compile when using dot notation here
            // even though the code work in dev mode
            const formList = action['content'].filter(f => !!f)
            const summary = action['summary']
            return adapter.addMany(formList, {
                ...state,
                isListLoading: false,
                totalFlamingoFormsCount: summary.totalItemCount
            })
        }

        case CreateOrUpdateFlamingoFormAction.type: {
            return {
                ...state,
                isFormCreatingOrUpdating: true
            }
        }
        case CreateOrUpdateFlamingoFormSuccessAction.type: {
            const formOTD = { ...action }
            delete formOTD.type
            const form = formOTD as Partial<FlamingoForm>
            // Make sure the updated meta data is reflected in the list right away
            return adapter.updateOne({
                id: form.formId,
                changes: {
                    ...form,
                }
            }, {
                ...state,
                isFormCreatingOrUpdating: false
            })
        }

        case DeleteFlamingoFormAction.type: {
            return {
                ...state,
                isListLoading: true
            }
        }

        case DeleteFlamingoFormSuccessAction.type: {
            return adapter.removeMany(action.formIds, {
                    ...state
                })
        }

        case DeleteFlamingoFormFailAction.type: {
            return {
                ...state,
                isListLoading: false,
                hasError: true
            }
        }

        default:
            return state
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors()
