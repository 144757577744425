import { createAction, props, union } from '@ngrx/store'

import { FlamingoFormsAPIResponse, FlamingoFormsQuery } from '@app/modules/flamingo/models/flamingo-form-query.model'
import { FlamingoFormCreateUpdateDTO, FlamingoFormDeleteDTO } from '@app/modules/flamingo/models/flamingo-form.model'

/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match */

const actionPrefix = '[Flamingo]'

export const GetFlamingoFormsAction =
    createAction(`${actionPrefix} Get Flamingo Forms for Current Plant`, props<FlamingoFormsQuery>())
export const GetFlamingoFormsSuccessAction =
    createAction(`${actionPrefix} Get Flamingo Forms for Current Plant Success`, props<FlamingoFormsAPIResponse>())
export const GetFlamingoFormsFailAction =
    createAction(`${actionPrefix} Get Flamingo Forms for Current Plant Fail`)

export const CreateOrUpdateFlamingoFormAction =
    createAction(`${actionPrefix} Create or Update Flamingo Forms`, props<FlamingoFormCreateUpdateDTO>())
export const CreateOrUpdateFlamingoFormSuccessAction =
    createAction(`${actionPrefix} Create Flamingo Forms Success`, props<FlamingoFormCreateUpdateDTO>())
export const CreateOrUpdateFlamingoFormFailAction =
    createAction(`${actionPrefix} Create Flamingo Forms Fail`)

export const DeleteFlamingoFormAction =
    createAction(`${actionPrefix} delete Flamingo Forms`, props<FlamingoFormDeleteDTO>())
export const DeleteFlamingoFormSuccessAction =
    createAction(`${actionPrefix} delete Flamingo Forms Success`, props<FlamingoFormDeleteDTO>())
export const DeleteFlamingoFormFailAction =
    createAction(`${actionPrefix} delete Flamingo Forms Fail`)

const all = union({
    GetFlamingoFormsAction,
    GetFlamingoFormsSuccessAction,
    GetFlamingoFormsFailAction,
    CreateOrUpdateFlamingoFormAction,
    CreateOrUpdateFlamingoFormSuccessAction,
    CreateOrUpdateFlamingoFormFailAction,
    DeleteFlamingoFormAction,
    DeleteFlamingoFormSuccessAction,
    DeleteFlamingoFormFailAction
})
export type FlamingoAction = typeof all
