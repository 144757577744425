import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { ToastrService } from 'ngx-toastr'
import { map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators'

import { mrmaAlertConfigs } from '@app/models/alert-configuration.model'
import { FlamingoFormsQuery } from '@app/modules/flamingo/models/flamingo-form-query.model'
import { FlamingoAPIService } from '@app/modules/flamingo/services/flamingo-api.service'
import { QuerySummary } from '@app/modules/shared/models/query-summary.model'
import { ResponseHandlerService } from '@app/services/response-handler.service'
import { ResponseHandlingStrategy } from '@app/services/response-handling-strategies/response-handling-strategy'
import { ResponseHandlingStrategyBuilder } from '@app/services/response-handling-strategy.builder'
import { AppSettings } from '@settings/app.settings'
import {
    CreateOrUpdateFlamingoFormAction, CreateOrUpdateFlamingoFormSuccessAction, DeleteFlamingoFormAction, DeleteFlamingoFormFailAction, DeleteFlamingoFormSuccessAction, GetFlamingoFormsAction, GetFlamingoFormsSuccessAction
} from './flamingo.actions'
import { currentQuery } from './flamingo.selectors'
import { Store } from '@ngrx/store'
import { AppState } from '../app.store'

@Injectable()
export class FlamingoEffects {

    getFlamingoFormsEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetFlamingoFormsAction),
            mergeMap((query) => {
                return this.responseHandlerService.query(
                    () => this.flamingoApiService.getFlamingoForms({
                        ...query,
                        itemPerPage: Math.max(query.itemPerPage, AppSettings.flamingoFormPageSize)
                    }),
                    this.customStrategy
                )
            }),
            map((response) => {
                const querySummary = JSON.parse(response.headers.get('query-summary')) as QuerySummary<FlamingoFormsQuery>
                const flamingoForms = response.body

                return GetFlamingoFormsSuccessAction({
                    summary: querySummary,
                    content: flamingoForms,
                })
            })
        )
    )

    createOrUpdateFlamingoFormsEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CreateOrUpdateFlamingoFormAction),
            switchMap((form) => {
                return this.responseHandlerService.query(
                    () => this.flamingoApiService.createOrUpdateFlamingoForm(form)
                )
            }),
            map((response) => {
                const form = response.body
                this.toastrService.success(
                    '',
                    'Form successfully registered with MRMA',
                    mrmaAlertConfigs.WorkflowSuccess.configuration
                )
                return CreateOrUpdateFlamingoFormSuccessAction(form)
            })
        )
    )

    deleteFlamingoFormsEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeleteFlamingoFormAction),
            switchMap((form) => {
                return this.responseHandlerService.query(
                    () => this.flamingoApiService.deleteFlamingoForm(form)
                ).pipe(
                    map(response => {
                        if (response.status === 204) {
                            this.toastrService.success(
                                '',
                                'Form successfully deleted with MRMA',
                                mrmaAlertConfigs.WorkflowSuccess.configuration
                            )
                            
                            return DeleteFlamingoFormSuccessAction(form)
                        } else {
                            return DeleteFlamingoFormFailAction()
                        }
                    })
                )
            })
        )
    )

    deleteFlamingoFormsSuccessEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeleteFlamingoFormSuccessAction),
            withLatestFrom(this.store.select(currentQuery)),
            map(([_, query]) => GetFlamingoFormsAction(query))
        )
    )

    private customStrategy: ResponseHandlingStrategy

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private flamingoApiService: FlamingoAPIService,
        private responseHandlerService: ResponseHandlerService,
        private toastrService: ToastrService,
    ) {
        this.customStrategy = new ResponseHandlingStrategyBuilder()
            .useRethrowError()
            .useShowToastrOnError(this.toastrService)
            .responseStrategy
    }

}
